<div class="row">
  <nav class="navbar navbar-inverse navbar-fixed-top">
    <div class="container">
      <div class="navbar-header">
        <a class="navbar-brand logo" [routerLink] ="['/']">
          <span class="glyphicon glyphicon-star back-icon" aria-hidden="true"></span> Atlas' Gallery
        </a>        
      </div>
      <ul class="nav navbar-nav account navbar-right">
          <li *ngIf="!(user|async)?.uid"><a routerLink="/login">Log In</a></li>
          <li *ngIf="(user|async)?.uid"><a routerLink="/upload">Upload Image</a></li>
          <li *ngIf="(user|async)?.uid"><a routerLink="/" (click)="logOut()">Log Out</a></li>
      </ul>
    </div>
  </nav>
</div>
