<div class="container">
  <form #loginForm="ngForm">
    <h1 class="login-title">Log In</h1>
    <div class="form-group">
      <label>Email</label>
      <input class="form-control" 
        #emailControl="ngModel" 
        placeholder="Email" 
        [(ngModel)]="email" 
        name="email" 
        email 
        required>
    </div>
    <div class="form-group">
      <label>Password</label>
      <input class="form-control" 
        #passwordControl="ngModel" 
        type="password"
        placeholder="Password" 
        [(ngModel)]="password" 
        name="password" 
        password 
        required>
    </div>
    <button type="submit" class="btn btn-info" (click)="signIn()">Sign In</button>
    <p *ngIf="errorMsg" class="text-danger">{{errorMsg}}</p>
  </form>
</div>