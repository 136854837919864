<!-- <div class="row">
    <div class="col-md-12 caption-row">
      <span id="caption">
        {{imageCaption}}
      </span>
    </div>
</div> -->

<div class="row">
  <div class="col-md-12">
    <img src="{{image}}" alt="" class="paper">
  </div>
</div>

