<div class="container">
  <div *ngIf="upload">
    <div class="progress">
      <div class="progress-bar progress-bar-animated" [ngStyle]="{ 'width': upload?.progress + '%'}">
          <span class="progress-text">Progress: {{upload?.progress}}% Complete</span>
      </div>
    </div>    
  </div>
  <h2 class="heading">Upload New Images</h2>
    <input type="file" (change)="handleFiles($event)" multiple>
  <button class="btn btn-primary" (click)="uploadFiles()">Upload</button>
</div>